import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide50/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide50/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide50/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide50/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide50/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      ¿Cómo crear un mapa de ventas en 5 pasos?
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Crear un mapa de ventas es una estrategia esencial para cualquier equipo
      de ventas que aspire a maximizar su efectividad y eficiencia. En esta
      guía, conocerás un enfoque práctico dividido en cinco pasos para
      desarrollar un mapa de ventas efectivo.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    La creación de un mapa de ventas efectivo es crucial para cualquier equipo
    de ventas que busque optimizar su rendimiento, comenzando con la
    segmentación de mercado para identificar y priorizar clientes potenciales
    por ubicación, tamaño de la empresa, industria y necesidades específicas.
    <br />
    <br />
    Este enfoque permite una estrategia de venta más dirigida y personalizada.
    La planificación de rutas óptimas sigue, enfocándose tanto en la logística
    de visitas físicas como en la secuencia de interacciones digitales, para
    mejorar la eficiencia del equipo de ventas.
  </p>
)

const data = {
  start: {
    support: "Guía | Telemarketing",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Define tu mercado objetivo.",
      },
      {
        key: 2,
        text: "Diseño del proceso de ventas.",
      },
      {
        key: 3,
        text: "Desarrollo de materiales de venta.",
      },
      {
        key: 4,
        text: "Establece métricas de seguimiento.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
